import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App" style={{backgroundColor: '#2D404F'}}>
      <a className="button" style={{position: 'fixed', left: `calc(50% - 101px)`, bottom: '26px',
      width: 'auto',
      height: '48px',
      padding: '0px 16px',
      borderRadius: '24px',
      backgroundColor: '#ef6c00',
      fontSize: '16px',
      boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
      boxSizing: 'border-box',
      transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontWeight: 700,
      lineHeight: 1.75,
      textDecoration: 'none',
      usserSelect: 'none',
      WebkitAppearance: 'none',
      WebkitTapHighlightColor: 'transparent',
      alignItems: 'center',
      verticalAlign: 'middle',
      display: 'inline-flex',
      color: 'white'
    }} href="https://commitswimming.com"><span>Try it FREE for 10 days</span></a>
      <img src="CommitStats.jpg" style={{width: '100%', maxWidth: '800px', marginBottom: '92px'}}/>
    </div>
  );
}

export default App;
